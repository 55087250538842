<template>
  <div class="spinner text-center">
    <clip-loader v-if="!useModal" :loading="show" :size="size"></clip-loader>
    <span v-if="!useModal && show">{{message}}</span>
    <b-modal v-if="useModal && show" :visible="true" :hide-header="true" :hide-footer="true" :no-close-on-backdrop="true" :no-close-on-esc="true" :centered="true" size="sm" body-class="text-center" :body-bg-variant="bodyBgVariant" :body-text-variant="bodyTextVariant">
      <clip-loader :loading="true" :size="size"></clip-loader>{{message}}
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { ClipLoader } from 'vue-spinner/dist/vue-spinner.min.js'

//// TODO we should use this component everywhere instead of the plain clip-loader
export default {
  props: {
    message: {
      type: String,
      default: 'Processing'
    },
    show: {
      type: Boolean,
      default: true
    },
    //// WARNING: using the spinner with modals could cause the modal not to
    //// work properly, because the "modal-open" class from the body tag will be
    //// removed when the spinner is hidden. Might be a possible bug somewhere?
    useModal: {
      type: Boolean,
      default: true
    },
    bodyBgVariant: {
      type: String,
      default: 'light'
    },
    bodyTextVariant: {
      type: String,
      default: 'dark'
    },
    size: {
      type: String,
      default: '60px'
    }
  },
  components: { ClipLoader },
}
</script>
